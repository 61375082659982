<template>
  <div class="practice-container">
    <div class="practice-content">
      <div class="menu-list">
        <el-menu
          :default-active="activeMenu"
          class="el-menu-vertical-demo"
          :router="true"
          unique-opened
        >
          <template v-for="item in menuList">
            <el-submenu
              v-if="item.children && item.children.length"
              :index="item.path + ''"
              :key="item.title"
            >
              <template slot="title">
                <i :class="item.icon + ' mr-8'"></i>
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item
                :index="el.path + ''"
                v-for="el in item.children"
                :key="el.title"
              >
                <span slot="title">{{ el.title }}</span>
              </el-menu-item>
            </el-submenu>

            <el-menu-item v-else :index="item.path + ''" :key="item.title">
              <i :class="item.icon + ' mr-8'"></i>
              <span slot="title">{{ item.title }}</span>
            </el-menu-item>
          </template>

          <!-- <el-submenu :index="item.path + ''" v-for="item in menuList" :key="item.title">
            <template slot="title">
              <i :class="item.icon + ' mr-8'"></i>
              <span>{{ item.title }}</span>
            </template>
            <el-menu-item :index="el.path + ''" v-for="el in item.children" :key="el.title">
              <span slot="title">{{ el.title }}</span>
            </el-menu-item>
          </el-submenu> -->
        </el-menu>
      </div>
      <div class="divContainer">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { StaffTypeEnum } from "@/utils/constant";
export default {
  computed: {
    RoleType() {
      const { matched } = this.$route;
      if (matched.some(({ path }) => path.includes("enterprise"))) {
        return StaffTypeEnum.Enterprise;
      } else if (matched.some(({ path }) => path.includes("school"))) {
        return StaffTypeEnum.School;
      }
      return undefined;
    },
    menuList() {
      let list = [];
      if (this.RoleType === StaffTypeEnum.School) {
        list = [
          // { title: "项目班", path: "/school/recruitnews" },
          {
            title: "项目班",
            path: "/school/practice/arrange",
            icon: "iconfont iconlianxiqiyeyuanxiao",
          },
          // { title: "奖惩记录", path: "/school/practice/rewards" },
          // { title: "日志批阅", path: "/school/practice/log" },
          {
            title: "假勤审批",
            path: "/school/practice/attendance-approve",
            icon: "iconfont iconshixishixun--shixikaohe",
          },
          {
            title: "打卡规则",
            path: "/school/practice/signrule",
            icon: "iconfont iconguanligongsixinxi",
          },
          {
            title: "打卡记录",
            path: "/school/practice/attendance",
            icon: "iconfont iconshixishixun--shixikaohe",
          },
          {
            title: "工作项目",
            path: "/school/practice/project",
            icon: "iconfont iconshixishixun-shixibaoming",
          },
          {
            title: "月度之星",
            path: "/school/practice/monthStar",
            icon: "iconfont iconxiangkan",
          },

          // {
          //   title: "能力档案",
          //   // icon: "iconfont iconshixishixun-shixibaoming",
          //   icon: "iconfont iconshixishixun-shixiguochengguanli",
          //   path: "/school/student",
          //   children: [
          //     { title: "能力档案", path: "/school/student" },
          //     { title: "能力管理", path: "/school/ability" },
          //   ]
          // },
          // {
          //   title: "项目班管理",
          //   icon: "iconfont iconshixishixun-shixibaoming",
          //   path: "/school/practice",
          //   children: [
          //     // { title: "岗位报名", path: "/school/practice" },
          //     { title: "项目班", path: "/school/practice/arrange" },
          //     // { title: "报名审批", path: "/school/practice/approve" },
          //     // { title: "实践实施", path: "/school/practice/implement" }
          //   ]
          // },
          // {
          //   title: "实践过程",
          //   icon: "iconfont iconshixishixun-shixiguochengguanli",
          //   path: "/school/project",
          //   children: [
          //     // { title: "工作项目", path: "/school/practice/project" },
          //     // { title: "实践检查", path: "/school/practice/inspect" },
          //     { title: "奖惩记录", path: "/school/practice/rewards" },
          //     { title: "日志批阅", path: "/school/practice/log" },
          //     { title: "假勤审批", path: "/school/practice/attendance-approve" },
          //     // { title: "实践审批", path: "/school/practice/internship-approve" },
          //     { title: "打卡规则", path: "/school/practice/signrule" },
          //     { title: "打卡记录", path: "/school/practice/attendance" },
          //     // { title: "薪资查询", path: "/school/practice/salary" }
          //   ]
          // },
          // {
          //   title: "实践考核",
          //   icon: "iconfont iconshixishixun--shixikaohe",
          //   path: "/school/evaluate",
          //   children: [
          //     { title: "实践评价", path: "/school/practice/evaluate" },
          //     { title: "实践成绩", path: "/school/practice/score" },
          //     { title: "实践总结", path: "/school/practice/summary" }
          //   ]
          // },
          // {
          //   title: "实践档案",
          //   icon: "el-icon-document-copy",
          //   path: "/school/file",
          //   children: [
          //     { title: "实践归档", path: "/school/practice/file" },
          //     { title: "实践轨迹", path: "/school/practice/track" }
          //     // { title: "实践报告", path: "/school/practice/report" },
          //     // { title: "实践成绩", path: "/school/practice/result" }
          //   ]
          // }
        ];
      } else {
        list = [
          {
            title: "实践报名",
            icon: "iconfont iconshixishixun-shixibaoming",
            path: "/enterprise/practice",
            children: [
              { title: "报名审批", path: "/enterprise/practice/approve" },
              // { title: "实践实施", path: "/enterprise/practice/implement" }
            ],
          },
          {
            title: "实践过程",
            icon: "iconfont iconshixishixun-shixiguochengguanli",
            path: "/enterprise/project",
            children: [
              { title: "工作项目", path: "/enterprise/practice/project" },
              { title: "岗位打卡", path: "/enterprise/practice/signrule" },
            ],
          },
          {
            title: "实践考核",
            icon: "iconfont iconshixishixun--shixikaohe",
            path: "/school/evaluate",
            children: [
              { title: "实践成绩", path: "/enterprise/practice/score" },
            ],
          },
        ];
      }
      return list;
    },
    activeMenu() {
      let route = this.$route;
      return route.path;
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.practice-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #f5f5f5;
  padding: 24px;
  .practice-content {
    display: flex;
    height: 100%;
    position: relative;
    .menu-list {
      position: absolute;
      top: 0;
      left: 0;
      width: 240px;
      height: 100%;
      overflow-y: auto;
      background-color: #fff;
      margin-right: 24px;
      border-radius: 8px;
      /deep/ .el-menu {
        border: none;
        .el-menu-item.is-active {
          color: #3d5afe;
          background-color: rgba(61, 90, 254, 0.1);
        }
      }
    }
    .divContainer {
      height: 100%;
      flex: 1;
      background-color: #fff;
      position: relative;
      margin-left: 264px;
      border-radius: 8px;
      .page-content {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        padding: 24px;
        background-color: #fff;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        overflow: auto;
      }
    }
  }
}
.el-menu-item.is-active {
  background: rgba($color: #3d5afe, $alpha: 0.08);
  position: relative;
  font-weight: bold;

  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 4px;
    height: 24px;
    background: #3d5afe;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 0px 4px 4px 0px;
  }
}
/deep/ .el-icon-star-on {
  width: 16px;
  height: 16px;
}
</style>
